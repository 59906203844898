@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.uk-margin-small-top {
  margin-top: 16px;
}

.categoryBlocks {
  h2 {
    margin: 0px;
  }

  @media (min-width: $breakpoint-small) {
    [class~="flicking-viewport"] {
      overflow-x: hidden;
      width: calc(100% + 0px);
      box-sizing: border-box;
    }
  }
}

.wrapper {
  position: relative;
  .flex {
    display: flex;
    gap: 12px;
    margin-top: 24px;
  }
}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  display: flex;
  width: calc(100% + 0px);
  flex-direction: row;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 8px;

  > div {
    &:not(:first-child) {
      margin-left: 8px;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.buttonWrapperNext {
  right: -16px;
  @media (max-width: $breakpoint-xsmall-max) {
    right: -8px;
  }
}

.sliderButton {
  @include button-reset();
  opacity: 1;
  width: 32px;
  height: 32px;
  background: $gray;
  box-shadow: 0px 2px 0px $gray-dark;
  border-radius: 2px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 8px);
  z-index: 5;
  left: -16px;

  &.sliderButtonNext {
    left: auto;
    right: -16px;
  }

  @media (max-width: $breakpoint-small) {
    display: none;
  }
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 140px;
  .categoryimage {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    img {
      border-radius: 2px;
      width: 140px;
      height: 140px;
      max-width: 140px;
      max-height: 140px;
      min-width: 140px;
      min-height: 140px;
      object-fit: cover;
    }
  }
}

.categoryTitle {
  margin-top: 8px;
  font-family: $global-font-family-secondary;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
