@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.search-input-field {
  width: 100%;
  display: inline-flex;
  padding: 10px 12px;
  align-items: center;
  background-color: $tint-gray-light;
  align-items: center;
  box-sizing: border-box;
  &__icon {
    margin-top: 2px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__clear-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  svg {
    padding: 1px;
  }
  input {
    border: none;
    box-shadow: none;
    padding: 0px;
    box-sizing: border-box;
    width: 100%;
    background-color: $tint-gray-light;
    font-size: 16px;
    font-family: Assistant;

    &:focus {
      outline: none;
    }
  }
}
